<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-icon
        :color="value.color"
        dark
        v-on="on"
      >{{value.icon}}</v-icon>
    </template>
    <span>{{value.text}}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: ['value']
}
</script>
