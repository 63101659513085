export const procsStatuses = [
  {
    title: 'Ожидает распределения',
    value: 1
  },
  {
    title: 'В очереди',
    value: 2
  },
  {
    title: 'В процессе',
    value: 3
  },
  {
    title: 'Завершен',
    value: 4
  },
  {
    title: 'В архиве',
    value: 5
  },
]

export const layersNames = [
  {
    id: -2,
    name: 'Подварка'
  },
  {
    id: -1,
    name: 'Прихватки'
  },
  {
    id: 1,
    name: 'Слой 1(корень)'
  },
  {
    id: 2,
    name: 'Слой 2'
  },
  {
    id: 3,
    name: 'Слой 3'
  },
  {
    id: 4,
    name: 'Слой 4'
  },
  {
    id: 5,
    name: 'Слой 5'
  },
  {
    id: 6,
    name: 'Слой 6'
  },
  {
    id: 7,
    name: 'Слой 7'
  },
  {
    id: 8,
    name: 'Слой 8'
  },
  {
    id: 9,
    name: 'Слой 9'
  },
  {
    id: 10,
    name: 'Слой 10'
  },
]

export const cardStatuses = [
  {
    id: 1,
    text: 'Ожидает рассмотрения',
    icon: 'mdi-file-question',
    color: 'blue'
  },
  {
    id: 2,
    text: 'Рассматривается',
    icon: 'mdi-file-find',
    color: 'orange'
  },
  {
    id: 3,
    text: 'С замечаниями',
    icon: 'mdi-file-remove',
    color: 'red'
  },
  {
    id: 4,
    text: 'Исправляется',
    icon: 'mdi-file-edit',
    color: 'orange'
  },
  {
    id: 5,
    text: 'Исправлена',
    icon: 'mdi-file-restore',
    color: 'green'
  },
  {
    id: 6,
    text: 'Ожидает рассмотрения',
    icon: 'mdi-file-question',
    color: 'blue'
  },
  {
    id: 7,
    text: 'Рассматривается',
    icon: 'mdi-file-find',
    color: 'orange'
  },
  {
    id: 8,
    text: 'С замечаниями',
    icon: 'mdi-file-remove',
    color: 'red'
  },
  {
    id: 10,
    text: 'Исправляется',
    icon: 'mdi-file-edit',
    color: 'orange'
  },
  {
    id: 11,
    text: 'Исправлена',
    icon: 'mdi-file-restore',
    color: 'green'
  },
  {
    id: 12,
    text: 'Рабочая',
    icon: 'mdi-file-restore',
    color: 'green'
  },
  {
    id: 13,
    text: 'Архив',
    icon: 'mdi-file-restore',
    color: 'green'
  },
  {
    id: 14,
    text: 'Удалена',
    icon: 'mdi-file-restore',
    color: 'green'
  }
]

export const tmplProcessStatuses = [
  {
    id: 1,
    text: 'Ожидает рассмотрения',
    icon: 'mdi-file-question',
    color: 'blue'
  },
  {
    id: 2,
    text: 'Рассматривается',
    icon: 'mdi-file-find',
    color: 'orange'
  },
  {
    id: 3,
    text: 'С замечаниями',
    icon: 'mdi-file-remove',
    color: 'red'
  },
  {
    id: 4,
    text: 'Исправляется',
    icon: 'mdi-file-edit',
    color: 'orange'
  },
  {
    id: 5,
    text: 'Исправлена',
    icon: 'mdi-file-restore',
    color: 'green'
  },
  {
    id: 6,
    text: 'Ожидает рассмотрения',
    icon: 'mdi-file-question',
    color: 'blue'
  },
  {
    id: 7,
    text: 'Рассматривается',
    icon: 'mdi-file-find',
    color: 'orange'
  },
  {
    id: 8,
    text: 'С замечаниями',
    icon: 'mdi-file-remove',
    color: 'red'
  },
  {
    id: 10,
    text: 'Исправлена',
    icon: 'mdi-file-edit',
    color: 'orange'
  },
  {
    id: 11,
    text: 'Исправлена',
    icon: 'mdi-file-restore',
    color: 'green'
  },
  {
    id: 12,
    text: 'Рабочая',
    icon: 'mdi-file-restore',
    color: 'green'
  },
  {
    id: 13,
    text: 'Архив',
    icon: 'mdi-file-restore',
    color: 'green'
  },
  {
    id: 14,
    text: 'Удалена',
    icon: 'mdi-file-restore',
    color: 'green'
  },
  {
    id: 15,
    text: 'Исправлена на согласовании',
    icon: 'mdi-file-restore',
    color: 'green'
  },
  {
    id: 16,
    text: 'С замечаниями',
    icon: 'mdi-file-restore',
    color: 'green'
  }
]
